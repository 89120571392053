
  @import '/src/shared/styles/_variables.scss';

.password-input {
  margin-bottom: 4px !important;
}

.rules {
    padding: 16px 0 24px 0;
    p {
      font-family: $inter;
      font-style: italic;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      flex-direction: column;
      color: $grey-very-dark;
    }

    .tick-container {
      background: #18bb15;
      width: 14px;
      height: 14px;
      position: absolute;
      border-radius: 100%;
      margin-right: 12px;

      .tick {
        display: inline-block;
        transform: rotate(45deg);
        height: 9px;
        width: 5px;
        border-bottom: 2px solid $white;
        border-right: 2px solid $white;
        position: absolute;
        left: 4px;
        top: 1px;
      }
    }
  }