
  @import '/src/shared/styles/_variables.scss';

.container {
  z-index: 2;
  width: 100vw;
  display: flex;
  height: calc(100vh - 64px);
  top: 64px;
  
  .inner-container {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0 20px 20px 20px;
    justify-content: center;
    align-items: flex-start;

    @include desktop {
      width: 50%;
      padding: 0 64px;
    }
    
    @include tablet {
      width: 50%;
      padding: 0 48px;
    }


    .authentication {
      text-align: left;
      max-width: 450px;
      width: 100%;
      margin-top: 60px;

      .sub-title {
        padding-top: 16px;
      }
    }
  }

  a {
    font-size: 13px;
    &.forgot {
      font-style: italic;
    }
  }
}
