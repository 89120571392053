
  @import '/src/shared/styles/_variables.scss';


.new-password {
    margin-top: 40px;
    
    input {
        margin-bottom: 24px;
    }
}