
  @import '/src/shared/styles/_variables.scss';

.account-image {
  width: 50%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('/images/signup-login.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: none;
  
  @include tablet {
    display: flex;
  }
}