// Beyond App Colours

$primary-color1: #FCFC45;
$primary-color2: #10DFC7;
$primary-color3: #F877E0;
$primary-color4: #55DAFC;
$primary-color5: #BE88F2;
$primary-color6: #b35802;

$primary-color4-light: #ddf8fe;


$grey-very-light: #F2F2F2;
$grey-light: #E0E0E0;
$grey-medium: #BDBDBD;
$grey-dark: #727272;
$grey-very-dark: #4F4F4F;
$grey-very-very-dark: #333333;
$placeholder: #828282;
$black: #111111;

$tertiary-color: #ffffff;

$primary-background-color: #ffffff;
$secondary-background-color: #ffffff;
$tertiary-background-color: #ffffff;

$white: #ffffff;
$off-white: #F4F4F4;

$small-devices: 576px;
$medium-devices: 768px;
$large-devices: 1025px;
$x-large-devices: 1200px;
$xx-large-devices: 1400px;

$inter: "Inter", Arial, Helvetica, sans-serif;
$giorgio: "GiorgioSans-Heavy", Arial, Helvetica, sans-serif;
$space-mono: "Space mono", Arial, Helvetica, sans-serif;


@mixin xs-vertical-mobile {
  // small devices (portrait phones, 440px and up)
  @media (max-height: #{$small-devices}) {
    @content;
  }
}

@mixin s-vertical-mobile {
  // small devices (portrait phones, 440px and up)
  @media (max-height: #{$medium-devices}) {
    @content;
  }
}

@mixin s-mobile {
  // small devices (portrait phones, 440px and up)
  @media (min-width: #{$small-devices}) {
    @content;
  }
}

@mixin mobile {
  // small devices (landscape phones, 576px and up)
  @media (min-width: #{$small-devices}) {
    @content;
  }
}

@mixin tablet {
  // medium devices (tablets, 768px and up)
  @media (min-width: #{$medium-devices}) {
    @content;
  }
}

@mixin desktop {
  // large devices (desktops, 1025px and up)
  @media (min-width: #{$large-devices}) {
    @content;
  }
}

@mixin l-desktop {
  // x-large devices (large desktops, 1200px and up)
  @media (min-width: #{$x-large-devices}) {
    @content;
  }
}

@mixin xl-desktop {
  // xx-large devices (larger desktops, 1400px and up)
  @media (min-width: #{$xx-large-devices}) {
    @content;
  }
}

@mixin truncate($truncation-boundary) {
  max-width: $truncation-boundary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
