
  @import '/src/shared/styles/_variables.scss';

.link {
  font-size: 16px;
  cursor: pointer;
  
  &:hover {
    text-decoration: underline !important;
  }
}
